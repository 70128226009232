<template>
    <div class="container-fluid">
        <div class="col-md-2 hidden-sm hidden-xs"></div>
        <div class="col-md-8">
            <div class="row" style="padding-bottom:50px">
                <div class="col-sm-12 text-center">
                    <h3>{{ $t('promotions.title') }}</h3>
                    <br>
                </div>
                <div class="col-sm-12">
                    <div class="p-grid">
                        <div class="card">
                            <img v-if="$i18n.locale == 'en'" :src="`${publicPath}images/points-en.jpg`" alt="promo" class="img-promo">
                            <img v-if="$i18n.locale == 'fr'" :src="`${publicPath}images/casino.jpg`" alt="promo" class="img-promo">
                            <h5>{{ $t('promotions.betshare') }} - {{ $t('promotions.how-to-participate') }}</h5>
                            
                            <div class="promo-body">
                                <p>{{ $t('promotions.betshare-p1') }} {{ $t('promotions.betshare-p2') }}</p>
                            </div>
                            <router-link to="/" tag="button" class="btn bg-orange btn-sm btn-block">{{ $t('promotions.play-now') }}</router-link>
                        </div>
                        <div class="card">
                            <img  v-if="$i18n.locale == 'en'" :src="`${publicPath}images/drop-n-wins-en.png`" alt="promo" class="img-promo">
                            <img  v-if="$i18n.locale == 'fr'" :src="`${publicPath}images/dropnwins.jpg`" alt="promo" class="img-promo">
                            <h5 style="text-transform:uppercase">{{ $t('promotions.drop-n-wins') }}</h5>
                            <p style="color:var(--bg-dark);margin-bottom: 0px;">dd</p>
                            <div class="promo-body">
                                <p>{{ $t('promotions.drop-p1') }}</p>
                            </div>
                            <router-link to="/casino-home" tag="button" class="btn bg-orange btn-sm btn-block">{{ $t('promotions.play-now') }}</router-link>
                        </div>
                        <div class="card">
                            <img :src="`${publicPath}images/banner1.jpg`" alt="promo" class="img-promo">
                            <h5>{{ $t('promotions.free-bet') }}</h5>
                            <p class="subtitle">{{ $t('promotions.how-to-participate') }}</p>
                            <div class="promo-body">
                                <p>{{ $t('promotions.free-bet-p1') }} {{ $t('promotions.free-bet-p2') }}</p>
                            </div>
                            <router-link to="/register" tag="button" class="btn bg-orange btn-sm btn-block">{{ $t('promotions.register') }}</router-link>
                        </div>
                        <div class="card">
                            <img :src="`${publicPath}images/banner2.jpg`" alt="promo" class="img-promo">
                            <h5>{{ $t('promotions.cashback') }}</h5>
                            <p class="subtitle">{{ $t('promotions.how-to-participate') }}</p>
                            <div class="promo-body">
                                <p>{{ $t('promotions.cashback-p1') }} {{ $t('promotions.cashback-p2') }}</p>
                            </div>
                            <router-link to="/" tag="button" class="btn bg-orange btn-sm btn-block">{{ $t('promotions.play-now') }}</router-link>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 hidden-sm hidden-xs"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            publicPath: process.env.BASE_URL || '/',
        }
    }
}
</script>
<style scoped>
.card {
    margin-bottom: 10px;
}
.img-promo {
    width: 100%;
    border-radius: 5px;
    max-height: 280px;
    min-height: 280px;

}
.subtitle {
    font-weight: 500;
}
.p-grid {
    display: grid;
    gap: 20px;
}
</style>